<template>
  <div class="bodyBg-image">
    <img src="@/assets/img/img3.png" class="img3">
    <en-my-header></en-my-header>
    <div class="pr pt40">
      <div class="w1200 box plr25">
        <mytitle title="Stomach health assessment"></mytitle>

        <el-table :data="tableData" border :header-cell-style="{background:'#F6F5FE'}" :header-cell-class-name='hdRed' style="width: 100%; margin-top: 20px">
          <el-table-column label="" align="center">
            <template slot-scope="{row}">
              <div>{{row.name1}}</div>
            </template>
          </el-table-column>
          <el-table-column label="Stagnation of Liver and Stomach Qi" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed2.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name2">{{scope.row.name2}}</div>
              <div v-if="scope.row.img2">
                <img :src="scope.row.img2.call(scope._self,scope.$index,scope.row)" alt="">
              </div>
              <div v-if="scope.row.she2" v-html="scope.row.she2.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="Liver and stomach stagnation-heat syndrome" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed3.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name3">{{scope.row.name3}}</div>
              <div v-if="scope.row.img3">
                <img :src="scope.row.img3.call(scope._self,scope.$index,scope.row)" alt="">
              </div>
              <div v-if="scope.row.she3" v-html="scope.row.she3.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="Spleen and stomach damp-heat syndrome" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed4.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name4">{{scope.row.name4}}</div>
              <div v-if="scope.row.img4">
                <img :src="scope.row.img4.call(scope._self,scope.$index,scope.row)" alt="">
              </div>
              <div v-if="scope.row.she4" v-html="scope.row.she4.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="Spleen and Stomach Qi Deficiency Syndrome" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed5.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name5">{{scope.row.name5}}</div>
              <div v-if="scope.row.img5">
                <img :src="scope.row.img5.call(scope._self,scope.$index,scope.row)" alt="">
              </div>
              <div v-if="scope.row.she5" v-html="scope.row.she5.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="Spleen and stomach deficiency and cold syndrome" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed6.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name6">{{scope.row.name6}}</div>
              <div v-if="scope.row.she6" v-html="scope.row.she6.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="Insufficiency of stomach yin" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed7.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name7">{{scope.row.name7}}</div>
              <div v-if="scope.row.she7" v-html="scope.row.she7.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="Stomach and Collateral Stasis Syndrome" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed8.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name8">{{scope.row.name8}}</div>
              <div v-if="scope.row.she8" v-html="scope.row.she8.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
        </el-table>
        <div class="f14 c999 pt20">According to the "Expert Consensus Opinions on the Diagnosis and Treatment of Chronic Gastritis (2017)", "Consensus Opinions on the Diagnosis and Treatment of Chronic Non-atrophic Gastritis with Integrated Traditional Chinese and Western Medicine (2017)"</div>
        <div class="f18 c333 mt30 bsnt" v-if="type != 0">If the symptoms of stomach bloating, dry stool, and loss of appetite occur repeatedly, it is recommended that you go to the hospital for treatment.</div>
      </div>
      <div class="w1200 box plr25 mt20">
        <div class="flex hd2 f18 c333">
          Recommend stomach health advice for you
        </div>
        <div class="f16 c666 pt20 btF">
          <div>1. Remind you to avoid the following eating habits: irregular meals, eating too fast, overeating, hot food, barbecue, salty taste, etc. </div>
          <div>2. Suggestions for regular diet: Have a regular diet, which should be "light, balanced, soft, warm, slow, and fine", and avoid smoking, alcoholism, coffee, strong tea and other unhealthy lifestyles. </div>
          <div>3. Personalized dietary recommendations: </div>
          <div>(1) For those with deficiency or weakness in the spleen and stomach, avoid undigestible things such as raw cold and unclean, glutinous rice, fried, hard and rough dry;</div>
          <div>(2) Those with dampness and heat in the spleen and stomach, especially should avoid spicy alcoholic wine, meaty and greasy products; </div>
          <div>(3) People with obvious gastric bloating and belching should eat less beans and dairy products;</div>
          <div>(4) Patients with chronic gastritis should try to avoid taking foods that irritate or damage the gastric mucosa (such as spicy foods, foods containing nitrite, etc.). </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getItem } from '@/assets/js/storage'
export default {
  name:'',
  data(){
    var that = this;
    return {
      type: 0,
      check: require('@/assets/img/check.png'),
      checkout: require('@/assets/img/checkout.png'),
      obj: {
        "wt": "",
        "wtcd": "",
        "wzcd": "",
        "zzfour": "",
        "aqcd": "",
        "fscd": "",
        "kgcd": "",
        "kkcd": "",
        "zznine": "",
        "zzten": "",
        "shexiang": {
          "yuban": 0,
          "huang0_bai1": 0,
          "hong0_danhong1_danbai2_qingzi3": 0,
          "chihen": 0,
          "bo0_hou1": 0,
          "dianci": 0,
          "ni": 0,
          "liewen": 0,
          "botuo": 0
        },
        "url": ""
      },
      titleRed1: false,
      titleRed2: false,
      titleRed3: false,
      titleRed4: false,
      titleRed5: false,
      titleRed6: false,
      titleRed7: false,
      tableData: [
        {
          name1: 'stomachache',
          name2: 'Pain',
          isRed2: () => {return that.obj.wt.includes('2') ? 'red' : ''},
          name3: 'Burning pain',
          isRed3: () => {return that.obj.wt.includes('4') ? 'red' : ''},
          name4: 'Pain',
          isRed4: () => {return that.obj.wt.includes('2') ? 'red' : ''},
          name5: 'Pain/dull pain',
          isRed5: () => {return that.obj.wt.includes('2') ? 'red' : ''},
          name6: 'dull pain',
          isRed6: () => {return that.obj.wt.includes('1') ? 'red' : ''},
          name7: 'Burning pain',
          isRed7: () => {return that.obj.wt.includes('4') ? 'red' : ''},
          name8: 'Pain/Tingling',
          isRed8: () => {return that.obj.wt.includes('3') ? 'red' : ''},
        },
        {
          name1: 'Belching',
          img2: () => {return that.obj.aqcd != '' ? that.check : that.checkout}
        },
        {
          name1: 'Acid reflux',
          img3: () => {return that.obj.fscd != '' ? that.check : that.checkout}
        },
        {
          name1: 'Dry mouth',
          img3: () => {return that.obj.kgcd != '' ? that.check : that.checkout}
        },
        {
          name1: 'bitter',
          img3: () => {return that.obj.kkcd != '' ? that.check : that.checkout}
        },
        {
          name1: 'Stool form',
          name3: 'Dry knot',
          isRed3: () => {return that.obj.zznine.includes('1') ? 'red' : ''},
          name4: 'Sticky/sparse',
          isRed4: () => {return that.obj.zznine.includes('1') || that.obj.zznine.includes('3') ? 'red' : ''},
          name5: 'Rare',
          isRed5: () => {return that.obj.zznine.includes('2') ? 'red' : ''},
          name6: 'Rare',
          isRed6: () => {return that.obj.zznine.includes('2') ? 'red' : ''},
          name7: 'Dry knot',
          isRed7: () => {return that.obj.zznine.includes('1') ? 'red' : ''},
        },
        {
          name1: 'Chills',
          img4: () => {return that.obj.zzten.includes('1') ? that.check : that.checkout},
          img5: () => {return that.obj.zzten.includes('1') ? that.check : that.checkout}
        },
        {
          name1: 'Loss of appetite',
          img3: () => {return that.obj.zzten.includes('2') ? that.check : that.checkout},
          img4: () => {return that.obj.zzten.includes('2') ? that.check : that.checkout},
          img5: () => {return that.obj.zzten.includes('2') ? that.check : that.checkout}
        },
        {
          name1: 'Mental fatigue',
          img3: () => {return that.obj.zzten.includes('5') ? that.check : that.checkout},
          img4: () => {return that.obj.zzten.includes('5') ? that.check : that.checkout},
          img5: () => {return that.obj.zzten.includes('5') ? that.check : that.checkout}
        },
        {
          name1: 'Upset and irritable',
          img2: () => {return that.obj.zzten.includes('6') ? that.check : that.checkout}
        },
        {
          name1: 'Tongue',
          she2: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 1 ? 'red' : '';
            var red2 = that.obj.shexiang.huang0_bai1 == 1 ? 'red' : '';
            var red3 = that.obj.shexiang.bo0_hou1 == 0 ? 'red' : '';
            var str = `<span class='${red1}'>Pale red tongue</span>,<span class='${red2}'>Moss white</span>,<span class='${red3}'>Thin</span>`
            return str;
          },
          she3: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 0 ? 'red' : '';
            var red2 = that.obj.shexiang.huang0_bai1 == 0 ? 'red' : '';
            var str = `<span class='${red1}'>Red tongue</span>,<span class='${red2}'>Moss yellow</span>`
            return str;
          },
          she4: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 0 ? 'red' : '';
            var red2 = that.obj.shexiang.huang0_bai1 == 0 ? 'red' : '';
            var red3 = that.obj.shexiang.ni == 1 ? 'red' : '';
            var str = `<span class='${red1}'>Red tongue</span>,<span class='${red2}'>Moss yellow</span>,<span class='${red3}'>greasy</span>`
            return str;
          },
          she5: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 1 ? 'red' : '';
            var red4 = that.obj.shexiang.chihen == 1 ? 'red' : '';
            var red2 = that.obj.shexiang.huang0_bai1 == 1 ? 'red' : '';
            var red3 = that.obj.shexiang.bo0_hou1 == 0 ? 'red' : '';
            var str = `<span class='${red1}'>Pale red tongue</span>,<span class='${red4}'>Tooth mark</span>,<span class='${red2}'>Moss white</span>,<span class='${red3}'>Thin</span>`
            return str;
          },
          she6: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 1 ? 'red' : '';
            var red4 = that.obj.shexiang.chihen == 1 ? 'red' : '';
            var red3 = that.obj.shexiang.bo0_hou1 == 0 ? 'red' : '';
            var str = `<span class='${red1}'>Pale red tongue</span>,<span class='${red4}'>Tooth marks</span>,<span class='${red3}'>Thin moss</span>`
            return str;
          },
          she7: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 0 ? 'red' : '';
            var red2 = that.obj.shexiang.liewen == 1 ? 'red' : '';
            var str = `<span class='${red1}'>Red tongue</span>,<span class='${red2}'>crack</span>`
            return str;
          },
          she8: () => {
            var red1 = that.obj.shexiang.yuban == 1 ? 'red' : '';
            var str = `<span class='${red1}'>Ecchymosis</span>`
            return str;
          },
        },
      ]
    }
  },
  mounted(){
    this.type = this.$route.query.type;
    this.obj = getItem('teach');
    console.log(this.tableData)
    // this.loadText();
    this.loadTitleRed();
  },
  methods:{
    loadTitleRed(){
      if(this.obj.wt.includes('2') || this.obj.aqcd != '' || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '1' || this.obj.shexiang.huang0_bai1 == '1' || this.obj.shexiang.bo0_hou1 == '0'){
        this.titleRed1 = true;
      }
      if(this.obj.wt.includes('4') || this.obj.fscd != '' || this.obj.kgcd != '' || this.obj.kkcd != '' || this.obj.zznine.includes(1) || this.obj.zzten.includes(6) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '0' || this.obj.shexiang.huang0_bai1 == '0'){
        this.titleRed2 = true;
      }
      if(this.obj.wt.includes('2') || this.obj.zznine.includes(1) || this.obj.zznine.includes(3) || this.obj.zzten.includes(2) || this.obj.zzten.includes(5) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '0' || this.obj.shexiang.huang0_bai1 == '0' || this.obj.shexiang.ni == '1'){
        this.titleRed3 = true;
      }
      if(this.obj.wt.includes('1') || this.obj.wt.includes('2') ||  this.obj.zznine.includes(2) || this.obj.zzten.includes(1) || this.obj.zzten.includes(2) || this.obj.zzten.includes(5) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '1' || this.obj.shexiang.chihen == '1' || this.obj.shexiang.huang0_bai1 == '1' || this.obj.shexiang.bo0_hou1 == '0'){
        this.titleRed4 = true;
      }
      if(this.obj.wt.includes('1') ||  this.obj.zznine.includes(2) || this.obj.zzten.includes(1) || this.obj.zzten.includes(2) || this.obj.zzten.includes(5) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '1' || this.obj.shexiang.chihen == '1' || this.obj.shexiang.bo0_hou1 == '0'){
        this.titleRed5 = true;
      }
      if(this.obj.wt.includes('4') || this.obj.zznine.includes(1) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '0' || this.obj.shexiang.liewen == '1'){
        this.titleRed6 = true;
      }
      if(this.obj.wt.includes('2') || this.obj.wt.includes('3') || this.obj.shexiang.yuban == '1'){
        this.titleRed7 = true;
      }
    },
    hdRed({row, column, rowIndex, columnIndex}){
      if(columnIndex != 0) {
        var index = 'titleRed' + columnIndex
        if(this[index]){
          return 'red'
        }
      }
      
    }
  }
}
</script>

<style scoped lang="scss">
  
  .pr{
    position: relative;
    z-index: 2;
    .box{
      background-color: #FFFFFF;
      border-radius: 20px;
      padding-bottom: 30px;
      .btF{
        line-height: 1.6;
      }
      .bsnt{
        height: 48px;
        line-height: 48px;
        background: #F7F5FF;
        border-radius: 8px;
        padding: 0 16px;
      }
      .hd2{
          width: 100%;
          height: 68px;
          border-bottom: 1px solid #EBEBEB;
          
        }
    }
  }
  
  .bodyBg-image{
    // height: 100vh;
    padding-bottom: 30px;
  }
  .img3{
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
</style>
